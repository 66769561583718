<template>
  <div v-if="isDataLoaded">
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="1000"
    >
      <!--begin::Modal dialog-->
      <div class="bg-white poppins">
        <!--begin::Modal header-->
        <div class="modal-header align-center">
          <h3 class="mb-0 font-weight-bolder">{{ $t("edit_user") }}</h3>
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-light"
            @click="toggleModal"
          >
            <span class="svg-icon">
              <v-icon size="22">mdi-close</v-icon>
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--begin::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
          <!--begin::Body-->
          <div class="row">
            <div class="h-75px col-12 col-sm-6 col-md-4">
              <v-text-field
                dense
                outlined
                v-model="formData.name"
                :label="$t('name')"
                required
                :error-messages="nameErrors"
                @input="$v.formData.name.$touch()"
                @blur="$v.formData.name.$touch()"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-4">
              <v-text-field
                dense
                outlined
                v-model="formData.email"
                :label="$t('email')"
              />
            </div>

            <div class="h-75px col-12 col-sm-6 col-md-4">
              <v-autocomplete
                v-model="formData.user_type_id"
                :label="$t('user_type')"
                :items="serverData.user_types"
                item-value="index"
                item-text="text"
                clearable
                dense
                outlined
                required
                :error-messages="userTypeIdError"
                @input="$v.formData.user_type_id.$touch()"
                @blur="$v.formData.user_type_id.$touch()"
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.username"
                :label="$t('Username')"
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.password"
                :label="$t('password')"
              />
            </div>

            <div class="h-75px col-12 col-sm-4">
              <v-text-field
                dense
                outlined
                v-model="formData.password_confirmation"
                :label="$t('confirm_password')"
              />
            </div>

            <div class="h-75px col-12">
              <v-file-input
                dense
                outlined
                v-model="formData.photo"
                multiple
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('photo')"
                clearable
              />
            </div>

            <div class="h-75px col-12">
              <v-text-field
                dense
                outlined
                v-model="formData.description"
                :label="$t('description')"
              />
            </div>

            <div class="h-75px col-12" v-if="isSalesRefVisible">
              <v-text-field
                dense
                outlined
                v-model="formData.so_reference_code"
                :label="$t('sales_reference_number')"
                required
              />
            </div>
            <input-group
              label="Organization"
              v-show="
                !is_user_type_super_admin &&
                !(is_user_type_org_admin && serverData?.divisions)
              "
            >
              <div
                v-if="serverData.organizations"
                class="col-12"
                :class="
                  is_user_type_org_admin && !serverData?.divisions
                    ? ''
                    : 'col-sm-6'
                "
              >
                <v-autocomplete
                  v-model="formData.organization_id"
                  :label="$t('organization')"
                  :items="serverData.organizations"
                  item-value="index"
                  item-text="text"
                  clearable
                  dense
                  outlined
                  required
                  @change="
                    () => {
                      formData.warehouses = [];
                      formData.divisions = [];
                      updateWarehouseList();
                    }
                  "
                  :error-messages="organization_idError"
                  @input="$v.formData.organization_id.$touch()"
                  @blur="$v.formData.organization_id.$touch()"
                />
              </div>
              <div
                class="h-75px col-12 col-sm-6"
                v-if="!is_user_type_org_admin"
              >
                <v-autocomplete
                  v-model="formData.divisions"
                  :label="$t('division')"
                  :items="list_of_divisions"
                  item-value="index"
                  item-text="text"
                  clearable
                  dense
                  outlined
                  required
                  multiple
                  :disabled="!formData.organization_id"
                />
              </div>
              <div class="h-75px col-12" v-if="!is_user_type_org_admin">
                <v-autocomplete
                  v-model="formData.warehouses"
                  class="warehouse-combobox"
                  :items="organizationRelatedWarehouses"
                  :label="$t('warehouses')"
                  :disabled="
                    serverData.organizations ? !formData.organization_id : false
                  "
                  multiple
                  clearable
                  dense
                  chips
                  outlined
                  item-value="index"
                  item-text="text"
                  required
                  :error-messages="warehousesError"
                  @input="$v.formData.warehouses.$touch()"
                  @blur="$v.formData.warehouses.$touch()"
                >
                  <template #prepend-item>
                    <v-checkbox
                      color="#0000008a"
                      class="pl-4 all-warehouse-checkbox mt-1"
                      :input-value="isAllWarehouseSelected"
                      @change="onSelectAllWarehouse"
                    >
                      <template #label>
                        <span
                          class="pt-2 pl-5 font-weight-medium warehouse-bulk-select poppins"
                        >
                          {{ $t("select_all_warehouses") }}
                        </span>
                      </template>
                    </v-checkbox>
                  </template>
                </v-autocomplete>
              </div>
            </input-group>
          </div>

          <v-card class="mt-6 mb-4">
            <v-toolbar-title
              class="lighten-3 h3 text-white text-center pa-1"
              :style="{
                'background-color':
                  $store.getters.currentUser.theme['text_color'],
              }"
              >{{ $t("roles") }}</v-toolbar-title
            >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="4"
                  v-for="role in serverData.roles"
                  :key="role.value"
                >
                  <v-switch
                    class="pt-0 mt-0"
                    hide-details
                    v-model="formData.roles"
                    :color="$store.getters.currentUser.theme['text_color']"
                    :label="role.text"
                    :value="role.index"
                    :error-messages="rolesErrors"
                    @input="$v.formData.roles.$touch()"
                    @blur="$v.formData.roles.$touch()"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!--end::Body-->

          <!--begin::Actions-->
          <div class="my-4 d-flex flex-grow-1 align-end justify-center">
            <button
              type="reset"
              class="btn btn-light mx-3 px-5 py-3 ls1"
              @click="resetEditForm"
            >
              {{ $t("reset") }}
            </button>
            <button
              type="submit"
              class="btn btn--export-filter px-5 py-3 ls1"
              @click="submitEditForm"
            >
              {{ $t("submit") }}
            </button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal dialog-->
    </v-dialog>
  </div>
</template>

<script>
import { getToken } from "@/core/services/jwt.service";
import axios from "axios";
import SwalService from "@/core/services/swal.service";
import validationMixin from "@/own/mixins/validationMixin.js";
import { required, requiredIf } from "vuelidate/lib/validators";
import InputGroup from "@/own/components/fulfillment/skus/InputGroup.vue";
export default {
  name: "EditItem",
  mixins: [validationMixin],
  components: { InputGroup },
  validations() {
    return {
      formData: {
        user_type_id: { required },
        organization_id: {
          required: requiredIf(() => {
            return (
              !!this.serverData.organizations && !this.is_user_type_super_admin
            );
          }),
        },
        name: { required },
        // email: { required, email },
        roles: { required },
        warehouses: {
          required: requiredIf(() => {
            !this.is_user_type_super_admin && !this.is_user_type_org_admin;
          }),
        },
        // password: {
        //   required: requiredIf(() => {
        //     return Boolean(this.formData.username?.length);
        //   }),
        // },
        // password_confirmation: {
        //   sameAsPassword: () => {
        //     return (
        //       this.formData.password === this.formData.password_confirmation
        //     );
        //   },
        //   required: requiredIf(() => {
        //     return Boolean(this.formData.username?.length);
        //   }),
        // },
      },
    };
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    allWarehouseSelected: false,
    organizationRelatedWarehouses: [],
    formData: {
      id: null,
      parent_id: null,
      user_type_id: null,
      name: null,
      email: null,
      username: "",
      password: "",
      password_confirmation: "",
      description: null,
      photo: null,
      roles: [],
      warehouses: [],
      so_reference_code: null,
      organization_id: null,
      divisions: [],
    },
  }),
  methods: {
    updateWarehouseList() {
      if (this.serverData.organizations) {
        this.organizationRelatedWarehouses = this.serverData.warehouses.filter(
          (warehouse) =>
            warehouse.organization_id == this.formData.organization_id
        );
      } else {
        this.organizationRelatedWarehouses = this.serverData.warehouses;
      }
    },
    toggleModal() {
      if (this.dialog) {
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: { ...this.$store.getters.getItemForAction }.id };
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/users/edit`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config).then((response) => {
        this.serverData = response.data;
        this.organizationRelatedWarehouses = this.serverData.warehouses;
        this.formData = response.data.user;
        // this.resetEditForm();
        this.pageLoader(false);
      });
    },
    /**
     * Create FormData obj and add data into it
     */
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] != "image" && item[0] != "photo") ||
          item[1] == 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it) => data.append(`${item[0]}[]`, it));
          } else if (
            typeof item[1] === "object" &&
            item[1] !== null &&
            !Array.isArray(item[1])
          ) {
            data.append(item[0], JSON.stringify(item[1]));
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] == "image" || item[0] == "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitEditForm() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.convertToFormData();
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/authorizations/users/update`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
        data: data,
      };
      axios(config)
        .then(() => {
          SwalService.successMessage({
            title: this.$t("updated"),
            html: this.$t("user_data_has_been_updated"),
          });
          this.refresher();
          this.toggleModal();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    /**
     * reset data to intial value
     */
    resetEditForm() {
      this.$v.$reset();
      // if (this.serverData) {
      //   let copy = { ...this.serverData }.user;
      //   Object.entries(this.formData).forEach((localData) => {
      //     Object.entries(this.serverData.user).forEach((serverData) => {
      //       if (
      //         localData[0] == serverData[0] &&
      //         localData[0] != "photo" &&
      //         localData[0] != "image"
      //       ) {
      //         this.formData[localData[0]] = copy[serverData[0]];
      //       }
      //     });
      //   });
      // } else {
      this.formData = {
        id: null,
        parent_id: null,
        user_type_id: null,
        name: null,
        email: null,
        username: "",
        password: "",
        password_confirmation: "",
        description: null,
        photo: null,
        roles: [],
        so_reference_code: null,
        warehouses: [],
        organization_id: null,
        divisions: [],
      };
      // }
    },
    onSelectAllWarehouse() {
      if (!this.isAllWarehouseSelected && this.allWarehouseSelected) {
        this.formData.warehouses = [];
        this.allWarehouseSelected = false;
      }
      if (
        !this.isAllWarehouseSelected &&
        !this.allWarehouseSelected &&
        this.organizationRelatedWarehouses?.length > 0
      ) {
        this.organizationRelatedWarehouses.map((item) => {
          this.formData.warehouses.push(item.index);
        });
        this.formData.warehouses = [
          ...new Map(this.formData.warehouses.map((v) => [v, v])).values(),
        ];
        this.allWarehouseSelected = true;
      } else {
        this.formData.warehouses = [];
        this.allWarehouseSelected = false;
      }
    },
  },
  computed: {
    is_user_type_org_admin() {
      try {
        let org_admin = this.serverData.user_types?.find(
          (user_type) => user_type.index === this.formData.user_type_id
        );
        if (org_admin) {
          return org_admin?.text == "Organization Admin" ? true : false;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    },
    is_user_type_super_admin() {
      try {
        let admin = this.serverData.user_types.find(
          (user_type) => user_type.index === this.formData.user_type_id
        );
        if (admin) {
          return admin.text == "Super Admin" ? true : false;
        } else {
          return false;
        }
      } catch {
        return false;
      }
    },
    list_of_divisions() {
      let divs = [];
      if (this.serverData?.organizations) {
        if (this.formData.organization_id) {
          divs = this.serverData.organizations.find(
            (org) => org.index == this.formData.organization_id
          ).divisions;
        }
      } else if (this.serverData?.divisions) {
        divs = this.serverData.divisions;
      }
      return divs;
    },
    organization_idError() {
      return this.handleFormValidation("organization_id", this);
    },
    organizationId() {
      return this.$store.getters.currentUser.data.organization_system_code;
    },
    isDataLoaded: function () {
      return !!this.serverData;
    },
    userTypeIdError() {
      return this.handleFormValidation("user_type_id", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    // passwordErrors() {
    //   return this.handleFormValidation("password", this);
    // },
    // password_confirmationErrors() {
    //   if (this.formData.username && this.formData.password)
    //     return this.handleFormValidation("password_confirmation", this);
    //   else return [];
    // },
    rolesErrors() {
      return this.formData.roles?.length > 0
        ? null
        : this.$t("roles_are_required");
    },
    warehousesError() {
      return this.handleFormValidation("warehouses", this, "formData");
    },
    isSalesRefVisible() {
      return this.formData.roles.indexOf(9) !== -1;
    },
    isAllWarehouseSelected() {
      const serverData = this.serverData.warehouses.map((item) => item.index);
      const selected = this.formData.warehouses;
      return (
        JSON.stringify(serverData.sort()) === JSON.stringify(selected.sort())
      );
    },
  },
};
</script>
